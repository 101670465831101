@import url('https://fonts.googleapis.com/css2?family=Russo+One&family=Saira+Condensed:wght@400;700&display=swap');

@font-face {
    font-family: 'ShiningForce Dialoguebox';
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/shining-force-large.ttf) format('truetype');
}

@font-face {
    font-family: 'ShiningForce Menu';
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/shining-force-ii-small.ttf) format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .guide-content h1,
    .guide-content h1 span,
    .guide-content h1 em {
        @apply mb-4 font-title text-3xl text-white;
    }
    .guide-content h2,
    .guide-content h2 span,
    .guide-content h2 em {
        @apply mb-3 font-title text-2xl text-white;
    }
    .guide-content h3,
    .guide-content h3 span,
    .guide-content h3 em {
        @apply mb-2 font-title text-xl text-white;
    }
    .guide-content p {
        @apply mb-8 text-lg text-dark-font;
    }
    .guide-content a {
        @apply text-lg text-light-font underline;
    }
    .guide-content ul,
    .guide-content ol {
        @apply mb-8;
    }
    .guide-content ul {
        @apply ml-6 list-disc;
    }
    .guide-content ol {
        @apply ml-3 list-decimal;
    }
    .guide-content li {
        @apply mb-2 text-lg text-dark-font;
    }
    .guide-content ol li {
        @apply ml-2 pl-1;
    }
    .guide-content blockquote {
        @apply mb-8 rounded-2xl  border border-sf-blue-highlight bg-sf-blue-darkest p-4 text-lg;
    }
    .guide-content blockquote p {
        @apply mb-0;
    }
    .guide-content table {
        @apply mb-8 border-sf-blue-highlight text-lg;
    }
    .guide-content td {
        @apply border-sf-blue-highlight px-4 text-justify text-dark-font;
    }
    .guide-content td img {
        @apply -mx-4;
    }
    .guide-content hr {
        @apply stage-gradient mb-8 border-t-2 border-sf-blue-highlight;
    }
    .guide-content iframe {
        @apply mx-auto mb-8;
    }
}

@layer components {
    .stage-gradient {
        mask-image: linear-gradient(
            to left,
            transparent 0%,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 1) 90%,
            transparent 100%
        );
        -webkit-mask-image: linear-gradient(
            to left,
            transparent 0%,
            rgba(0, 0, 0, 1) 10%,
            rgba(0, 0, 0, 1) 90%,
            transparent 100%
        );
    }

    .bg-grid-gradient {
        mask-image: linear-gradient(
            to left,
            transparent 0%,
            rgba(0, 0, 0, 1) 40%,
            rgba(0, 0, 0, 1) 60%,
            transparent 100%
        );
        -webkit-mask-image: linear-gradient(
            to left,
            transparent 0%,
            rgba(0, 0, 0, 1) 40%,
            rgba(0, 0, 0, 1) 60%,
            transparent 100%
        );
    }

    .game-selection-gradient {
        mask-image: linear-gradient(
            to left,
            transparent 10%,
            rgba(0, 0, 0, 1) 30%,
            rgba(0, 0, 0, 1) 70%,
            transparent 90%
        );
        -webkit-mask-image: linear-gradient(
            to left,
            transparent 10%,
            rgba(0, 0, 0, 1) 30%,
            rgba(0, 0, 0, 1) 70%,
            transparent 90%
        );
    }

    .sf-border {
        border: 7px solid transparent;
        border-image: url(../assets/sf-border.png) 10 round;
    }

    .sf-border-small {
        border: 3px solid transparent;
        border-image: url(../assets/sf-border.png) 10 round;
    }
}

* {
    font-family: 'Saira Condensed', sans-serif;
}
